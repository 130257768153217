<!-- <template>
  <div ref="homeClassifyMenu" class="home-classify-menu">

    <div class="content">
      <div class="content-children" :class="edit ? 'edit' : ''">
        <div
          v-for="(item, index) in showCommonDataList"
          :key="index"
          class="content-item"
          :class="lineStyle(index)"
          @click="edit ? '' : clickMenu(item)">
          <div class="content-item-icon" :style="'background-color:' + item.customColor">
            <en-icon :name="customIcon(item.customIcon)"></en-icon>
          </div>
          <div class="content-item-text">{{ item.name }}</div>
          <en-icon v-if="edit" class="content-item-delete" name="shanchu-kuaijiecaozuo"  @click.native="deleteCommonMenu(item)"></en-icon>
        </div>
      </div>
    </div>
  </div>
</template> -->
<script>

import { menuClassifyTypeEnum, menuRouterData } from "@/api/homeMenu/handleMenu";

export default {
  name: "home-classify-menu",
  components: {

  },
  props: {
    menuData: {
      type: Object
    },
    edit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      lineCount: 0,
      showEdit: false,
      headerStyle: {

      },
      defaultHeaderStyle: {
           name: "221241",
          showName: false,
          titleText: {
              fontSize: "22px",
              isWeight: false,
              alignment: "juzhongduiqi1",
              color: "#f7290a"
          },
          titleStyle: 1,
          chartStyle: 0,
          iconColor: "#f00",
          chartColorGrou: "1",
          templateText: {
              fontSize: "16px",
              isWeight: false,
              color: "#fff"
          },
          statisticsNum: true,
          statisticsText: {
              fontSize: "20px",
              isWeight: false,
              color: "#fff"
          },
          alignment: 2,
          textSorting: 2,
          textAlignment: "center",
          cartBg: {
              style: 1,
              color: "#fff"
          },
          borderStyle: {
              style: "1",
              size: "3px",
              color: "#f01"
          },
          bgStyle: "#fff"
      },
      chartBgColorList: ["#3E90FE", "#46D48A", "#FFA32C", "#FD635B", "#48BAFF", "#A971F6"],
      chartBgColorList2: ["rgba(62, 144, 254,0.2)", "rgba(70, 212, 138,0.2)", "rgba(255, 163, 44,0.2)", "rgba(253, 99, 91,0.2)", "rgba(72, 186, 255,0.2)", "rgba(169, 113, 246,0.2)"]
    };
  },
  created() {
    // console.log(this.menuData, "this.menuData进入了几次");
    this.getHeaderStyleData();
  },
  watch: {
    menuData(newValue) {
      this.getHeaderStyleData();
      // console.log(newValue, "card-headers");
    }
  },
  mounted() {
    // this.$nextTick(() => {
    //   this.updateLineCount(this.$el.clientWidth);
    //   const self = this;
    //   const myObserver = new ResizeObserver((entries) => {
    //     self.updateLineCount(entries[0].contentRect.width);
    //   });
    //   myObserver.observe(this.$refs.homeClassifyMenu);
    // });
  },
  computed: {
    title() {
      return this.menuData.name;
    },
    dataList() {
      return this.menuData.dataList;
    },
    commonDataList() {
      return this.dataList.filter((item) => item.isCommon === "000");
      // return this.dataList;
    },
    showCommonDataList() {
      return this.commonDataList.length === 0 ? this.dataList : this.commonDataList;
    }

  },
  methods: {
    getHeaderStyleData() {
      // console.log(JSON.parse(this.menuData.headerStyle), "看看数据看看数据");
      if (this.menuData?.headerStyle) {
        this.headerStyle = JSON.parse(this.menuData.headerStyle);
        if (this.headerStyle.borderStyle.style === "0") {
          this.headerStyle.borderStyle.styleLine = "none";
        }
        if (this.headerStyle.borderStyle.style === "1") {
          this.headerStyle.borderStyle.styleLine = "solid";
        }
        if (this.headerStyle.borderStyle.style === "2") {
          this.headerStyle.borderStyle.styleLine = "dashed";
        }
        if (this.headerStyle.borderStyle.style === "3") {
          this.headerStyle.borderStyle.styleLine = "solid";
          this.headerStyle.borderStyle.size = "3px";
        }
      } else {
        this.headerStyle = this.defaultHeaderStyle;
      }
      console.log(this.headerStyle, "ssssss");
    },
    cardlinde() {
      return {
        border: `${this.headerStyle.borderStyle.styleLine} ${this.headerStyle.borderStyle.size} ${this.headerStyle.borderStyle.color}`
      };
    },
    updateLineCount(width) {
      const lineCount = parseInt(String((width - 20) / (318 + 20)), 10);
      this.lineCount = lineCount > 0 ? lineCount : 4;
    },
    customIcon(icon) {
      if (icon && icon.indexOf("-shixin") > -1) {
        return icon.replace("-shixin", "-duose");
      }
      return icon;
    },
    deleteCommonMenu(menu) {
      if (this.commonDataList.length <= 1) {
        this.deleteData();
      } else {
        this.$emit("deleteCommonMenu", menu);
      }
    },
    deleteData() {
      this.$emit("delete");
    },
    clickMenu(menu) {
      if (menu.dataType === 15) {
          // 外部链接
          const url = menu.outUrl;
          if (menu.outType === 1) {
              window.open(url);
          } else {
              window.location.href = url;
          }
          return;
      }
      const { path, query } = menuRouterData(menu);
      this.$router.push({ path, query });
    },
    iconColorBg2(index) {
      if (index <= 6) {
        return this.chartBgColorList2[index];
      }
        return this.chartBgColorList2[index % 6];

      // return this.chartBgColorList[index];
    },
    iconColorBg(index) {
      if (index <= 6) {
        return this.chartBgColorList[index];
      }
        return this.chartBgColorList[index % 6];

      // return this.chartBgColorList[index];
    },
    lineStyle(index) {
      console.log(index, "进入这里了嘛", this.lineCount, this.commonDataList);
      const index1 = index + 1;
      const style = (index1 % this.lineCount) > 0 && index1 < this.commonDataList.length ? "content-item-line" : "";
      return style;
    },

    layoutList1(item) {
        // <el-option label="图标居文字上方" value="1"></el-option>
        // <el-option label="图标居文字下方" value="2"></el-option>
        // <el-option label="图标居文字左方" value="3"></el-option>
        // <el-option label="图标居文字右方" value="4"></el-option>
        // headerStyle.alignment
        // this.headerStyle.chartStyle = 1，7， 8， 没有图标
        // this.headerStyle.chartStyle = 2，4，5 才有icon左右
         // this.headerStyle.chartStyle = 3，6 才有icon上下
        return (
            <span
                style={{
                    padding: "10px"
                }}
            >
                <div class="content-item-text" style={{
                  "text-align": this.headerStyle.textAlignment,
                                  fontSize: this.headerStyle.templateText.fontSize,
                  color: this.headerStyle.templateText.color
                  }}>{item.name}</div>
                {
                    this.headerStyle.statisticsNum && <div class="content-item-text" style={{
                                            fontSize: this.headerStyle.statisticsText.fontSize
                                        }}></div>
                }
            </span>
        );
    },
    layoutList2(item, index) {
        const icon = () => <div class="content-item-icon style2" style={{
                     "background-color": this.iconColorBg(index),
                     float: "left"
                }}>
                  <en-icon name={this.customIcon(item.customIcon)}></en-icon>
                </div>;

        const text = () => {
            const name = () => <div class="content-item-text" style={{
 fontSize: this.headerStyle.templateText.fontSize,
            color: this.headerStyle.templateText.color
}}>{item.name}</div>;
            const statisticsNum = () => {
                if (this.headerStyle.statisticsNum) {
                    return <div class="content-item-text"
                            style={{
                                    fontSize: this.headerStyle.statisticsText.fontSize
                                }}
                            ></div>;
                }
                return "";
            };

            // 文字和统计数  上下布局
            const textSortTop = () => <span style={{
                        float: "left",
                        margin: "3px 0 0 10px"
                    }}>
                        {name()}
                        {statisticsNum() }
                </span>;
            const textSortBottom = () => <span style={{
                        float: "left",
                        margin: "3px 0 0 10px"
                    }}>
                        {statisticsNum() }
                        {name()}
                </span>;
            if (this.headerStyle.textSorting === 1) {
                return textSortTop();
            }
            if (this.headerStyle.textSorting === 2) {
                return textSortBottom();
            }

            return textSortTop();
        };
        // console.log(text());

        // 文字和图标 左右布局
        const leftright = () => <span style={{
                    padding: "10px"
                }}>
                    {icon()}
                   {text()}
                </span>;
        const rightleft = () => <span style={{
                    padding: "10px"
                }}>
                    {text()}
                    {icon()}
                </span>;

        if (this.headerStyle.alignment === 3) {
            return leftright();
        }
        if (this.headerStyle.alignment === 4) {
            return rightleft();
        }

        return leftright();
    },
    layoutList3(item, index) {
        const icon = () => <div class="content-item-icon" style={{
                     "background-color": this.iconColorBg(index)
                }}>
                  <en-icon name={this.customIcon(item.customIcon)}></en-icon>
                </div>;

        const text = () => {
           const name = () => <div class="content-item-text" style={{
            fontSize: this.headerStyle.templateText.fontSize,
            "margin-top": "5px",
                      color: this.headerStyle.templateText.color
            }}>{item.name}</div>;
            const statisticsNum = () => {
                if (this.headerStyle.statisticsNum) {
                    return <div class="content-item-text"
                            style={{
                                    fontSize: this.headerStyle.statisticsText.fontSize
                                }}
                            ></div>;
                }
                return "";
            };

            // 文字和统计数  上下布局
            const textSortTop = () => <span>
                        {name()}
                        {statisticsNum() }
                </span>;

            return textSortTop();
        };
        const box = () => <div class="content-box4">
                      {icon()}
                      {text()}
                </div>;
      return box();
    },
    layoutList4(item, index) {
      const icon = () => <div class="content-item-icon style4" style={{
                     "background-color": this.iconColorBg(index),
                     float: "left"
                }}>
                  <en-icon name={this.customIcon(item.customIcon)}></en-icon>
                </div>;

        const text = () => {
            const name = () => <div class="content-item-text" style={{
 fontSize: this.headerStyle.templateText.fontSize,
            color: this.headerStyle.templateText.color
}}>{item.name}</div>;
            const statisticsNum = () => {
                if (this.headerStyle.statisticsNum) {
                    return <div class="content-item-text"
                            style={{
                                    fontSize: this.headerStyle.statisticsText.fontSize
                                }}
                            ></div>;
                }
                return "";
            };

            // 文字和统计数  上下布局
            const textSortTop = () => <span style={{
                        float: "left",
                        margin: "3px 0 0 10px"
                    }}>
                        {name()}
                        {statisticsNum() }
                </span>;
            const textSortBottom = () => <span style={{
                        float: "left",
                        margin: "3px 0 0 10px"
                    }}>
                        {statisticsNum() }
                        {name()}
                </span>;
            if (this.headerStyle.textSorting === 1) {
                return textSortTop();
            }
            if (this.headerStyle.textSorting === 2) {
                return textSortBottom();
            }

            return textSortTop();
        };
        // console.log(text());

        // 文字和图标 左右布局
        const leftright = () => <span style={{
                    textAlign: "center",
                    padding: "10px"
                }}>
                    {icon()}
                   {text()}
                </span>;
        const rightleft = () => <span style={{
                    textAlign: "center",
                    padding: "10px"
                }}>
                    {text()}
                    {icon()}
                </span>;

        if (this.headerStyle.alignment === 3) {
            return leftright();
        }
        if (this.headerStyle.alignment === 4) {
            return rightleft();
        }

        return leftright();
    },
    layoutList5(item, index) {
      const icon = () => <div class="content-item-icon style5" style={{
                     "background-color": this.iconColorBg2(index),
                     float: "left"
                }}>
                  <en-icon style={{ background: this.iconColorBg(index) }} name={this.customIcon(item.customIcon)}></en-icon>
                </div>;

        const text = () => {
            const name = () => <div class="content-item-text" style={{
 fontSize: this.headerStyle.templateText.fontSize,
              color: this.headerStyle.templateText.color
             }}>{item.name}</div>;
            const statisticsNum = () => {
                if (this.headerStyle.statisticsNum) {
                    return <div class="content-item-text"
                            style={{
                                    fontSize: this.headerStyle.statisticsText.fontSize
                                }}
                            ></div>;
                }
                return "";
            };

            // 文字和统计数  上下布局
            const textSortTop = () => <span style={{
                        float: "left",
                        margin: "3px 0 0 10px"
                    }}>
                        {name()}
                        {statisticsNum() }
                </span>;
            const textSortBottom = () => <span style={{
                        float: "left",
                        margin: "3px 0 0 10px"
                    }}>
                        {statisticsNum() }
                        {name()}
                </span>;
            if (this.headerStyle.textSorting === 1) {
                return textSortTop();
            }
            if (this.headerStyle.textSorting === 2) {
                return textSortBottom();
            }

            return textSortTop();
        };
        // console.log(text());

        // 文字和图标 左右布局
        const leftright = () => <span style={{
                    textAlign: "center",
                    padding: "10px"
                }}>
                    {icon()}
                   {text()}
                </span>;
        const rightleft = () => <span style={{
                    textAlign: "center",
                    padding: "10px"
                }}>
                    {text()}
                    {icon()}
                </span>;

        if (this.headerStyle.alignment === 3) {
            return leftright();
        }
        if (this.headerStyle.alignment === 4) {
            return rightleft();
        }

        return leftright();
    },
    layoutList6(item, index) {
      const icon = () => <div class="content-item-icon" style={{
                     float: "left"
                }}>
                  <en-icon style={{ background: this.iconColorBg(index) }} name={this.customIcon(item.customIcon)}></en-icon>
                </div>;

        const text = () => {
            const name = () => <div class="content-item-text" style={{
 "margin-top": "10px",
color: this.headerStyle.templateText.color,
             fontSize: this.headerStyle.templateText.fontSize
}}>{item.name}</div>;
            const statisticsNum = () => {
                if (this.headerStyle.statisticsNum) {
                    return <div class="content-item-text"
                            style={{
                                    fontSize: this.headerStyle.statisticsText.fontSize
                                }}
                            ></div>;
                }
                return "";
            };

            // 文字和统计数  上下布局
            const textSortTop = () => <span>
                        {name()}
                        {statisticsNum() }
                </span>;

            return textSortTop();
        };
        const styleBox = () => <div class="style6" style={{
                     "background-color": this.iconColorBg2(index)
                }}>
          {icon()}
          {text()}
        </div>;
        return styleBox();
    },
    layoutList7(item, index) {
      const icon = () => <div class="content-item-icon icon-style7" style={{
                     float: "left"
                }}>
                  <en-icon name={this.customIcon(item.customIcon)}></en-icon>
                </div>;

        const text = () => {
            const name = () => <div class="content-item-text" style={{
 "margin-top": "10px",
color: this.headerStyle.templateText.color,
             fontSize: this.headerStyle.templateText.fontSize
}}>{item.name}</div>;
            const statisticsNum = () => {
                if (this.headerStyle.statisticsNum) {
                    return <div class="content-item-text"
                            style={{
                                    fontSize: this.headerStyle.statisticsText.fontSize
                                }}
                            ></div>;
                }
                return "";
            };

            // 文字和统计数  上下布局
            const textSortTop = () => <span>
                        {name()}
                        {statisticsNum() }
                </span>;

            return textSortTop();
        };
        const styleBox = () => <div class="style7" >
          {icon()}
          {text()}
        </div>;
        return styleBox();
    },
    layoutList8(item, index) {
      const icon = () => <div class="content-item-icon icon-style8" style={{
                     float: "left"
                }}>
                  <en-icon name={this.customIcon(item.customIcon)}></en-icon>
                </div>;

        const text = () => {
            const name = () => <div class="content-item-text" style={{
 "margin-top": "10px",
color: this.headerStyle.templateText.color,
             fontSize: this.headerStyle.templateText.fontSize
}}>{item.name}</div>;
            const statisticsNum = () => {
                if (this.headerStyle.statisticsNum) {
                    return <div class="content-item-text"
                            style={{
                                    fontSize: this.headerStyle.statisticsText.fontSize
                                }}
                            ></div>;
                }
                return "";
            };

            // 文字和统计数  上下布局
            const textSortTop = () => <span>
                        {name()}
                        {statisticsNum() }
                </span>;

            return textSortTop();
        };
        const styleBox = () => <div class="style8" >
          {icon()}
          {text()}
        </div>;
        return styleBox();
    },
    layoutStyle(item, index) {
        // console.log(this.headerStyle.chartStyle, "this.headerStyle.chartStyle");
       switch (this.headerStyle.chartStyle) {
            case 0:
               return this.layoutList1(item, index);
            case 1:
               return this.layoutList2(item, index);
            case 2:
                return this.layoutList3(item, index);
            case 3:
                return this.layoutList4(item, index);
            case 4:
                return this.layoutList5(item, index);
            case 5:
                return this.layoutList6(item, index);
            case 6:
              return this.layoutList7(item, index);
            case 7:
                 return this.layoutList8(item, index);
            default:
               return this.layoutList1(item, index);
       }
    }
  },
  render() {
    return (
        <div class="content">
          <div class={{
              "content-children": true,
              edit: !!this.edit
          }} >
            {
                this.showCommonDataList.map((item, index) => (
                        <div
                            style={ (this.headerStyle.chartStyle === 6 || this.headerStyle.chartStyle === 7)
                            ? this.cardlinde() : {} }
                            class={`content-item ${this.lineStyle(index)}`}
                            onClick={() => {
                                if (!this.edit) {
                                    this.clickMenu(item);
                                }
                            }}
                            >
                            {this.layoutStyle(item, index)}
                            {this.edit && (
                                  <en-icon
                                    class="content-item-delete"
                                    name="shanchu-kuaijiecaozuo"
                                    onClick={() => { this.deleteCommonMenu(item); }}
                                  ></en-icon>
                                )
                            }
                          </div>
                    ))
            }

          </div>
        </div>

    );
  }
};
</script>
<style scoped>
.content {
  padding-right: 20px;
  padding-bottom: 19px;
  height: calc(100% - 50px);
  overflow-y: auto;
}
.content .content-children {
  display: inline-block;
  text-align: left;
  width: 100%;
}
.content .content-children .content-item {
  display: inline-flex;
  margin-top: 16px;
  margin-left: 20px;
  min-width:120px;
  /* width: 200px; */
  /* height: 74px; */
  border-radius: 6px;
  align-items: center;
  position: relative;
  cursor: pointer;
  width: 21%;
  background-color: #3e90fe;
}
.content .content-children .content-item:hover {
  background-color: #7aaff5;
}
.content .content-children .content-item .content-item-delete {
  position: absolute;
  width: 16px;
  height: 16px;
  color: #A9B5C6;
  right: -6px;
  top:-6px;
}
.content .content-children .content-item>span {
  width: 100%;
}
.content .content-children .content-item  .content-item-text {
  font-size: 12px;
  color: #333333;
  /* margin-left: 12px; */
}
.content .content-children .content-item  .content-item-icon {
  background-color: #f76b6b;
  width: 40px;
  height: 40px;
  text-align: center;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  /* margin-left: 17px; */
}
.content .content-children .content-item .content-item-icon .en-icon {
  width: 18px;
  height: 18px;
  color: white;
  border-radius: 3px;
}
.content .content-children .content-item-line:after {
  display: block;
  content: "";
  border-right: 1px solid #e8ecf2;
  height: 74px;
  right: -10px;
  position: absolute;
  vertical-align: middle;
}
.content .edit .content-item {
  border: 1px solid transparent;
  background-color: #f5f8fd;
}
.content .edit .content-item:hover {
  background-color: #f5f8fd;
  border: 1px solid rgba(162,205,247,1);
}
.content .edit .content-item:hover .content-item-delete {
  color: #F76B6B;
}
.content-box4{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
.style2{
  position: relative;
  overflow: hidden;
}
.style2::after{
  position: absolute;
  content: " ";
  width: 100%;
  height: 100%;
  border-radius: 40%;
  background: #FFFFFF;
  opacity: 0.2;
  bottom: -25px;
  z-index: 101;
}
.style4{
  border-radius: 30%!important;
}
.style5{
  border-radius: 10px!important;
}
.style6{
  width: 100px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 10px!important;
}
.style6 .content-item-icon{
  background-color: transparent !important;
}
.style6 .en-icon{
  width: 24px!important;
  height: 24px!important;
}
.style7{
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  height: 100px;
  position: relative;
  background:linear-gradient(to right,#2195EE,rgba(76,215,253,0.79))
}
.style7 .content-item-icon{
 width: 100px!important;
 height: 100px!important;
 border-radius: 50%;
 border: 1px solid transparent;
 background-color: rgba(255,255,255,0.1)!important;
  box-shadow: 0px 0px 16px 0px rgba(40,136,248,0.79);
  position: absolute;
  top: 10px;
  right: -20px;
}
.style7 .content-item-text{
  /* color: #fff!important; */
  padding-left: 20px;

}
.style7 .en-icon{
  width: 52px!important;
  height: 52px!important;
  color: #fff;
}
.style8{
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  height: 100px;
  position: relative;
  background: linear-gradient(60deg, #D4FDFF, #9CD7F7);
}
.style8 .content-item-icon{
  width: 60px!important;
 height: 60px!important;
  background-color: rgba(255,255,255,0.3)!important;
  border: 1px solid rgba(255,255,255,0.6);
  border-radius: 50%;
  position: absolute;
  bottom: 15px;
  right: 15px;
}
.style8 .content-item-text{
  /* color: #3E90FE!important; */
  font-weight: 400;
  padding-left: 20px;

}
.style8 .en-icon{
  width: 28px!important;
  height: 28px!important;
  color: #fff;
}
</style>

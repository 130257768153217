var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "homeClassifyMenu",
      staticClass: "home-classify-menu",
      style: _vm.cardBg,
    },
    [
      _c("card-header", {
        attrs: {
          title: _vm.menuData.name,
          edit: _vm.edit,
          menuData: _vm.menuData,
        },
        on: {
          deleteData: _vm.deleteData,
          editTheme: _vm.editTheme,
          editData: _vm.editData,
        },
        scopedSlots: _vm._u([
          {
            key: "right",
            fn: function () {
              return [
                _c(
                  "div",
                  [
                    _vm.edit
                      ? _c("en-icon", {
                          staticClass: "header-right-edit",
                          attrs: { name: "bianji1", size: "12px" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.editData.apply(null, arguments)
                            },
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("class-Menu-Content", {
        attrs: { edit: _vm.edit, menuData: _vm.menuData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }